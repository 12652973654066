export default {
    /**
     * @description 配置显示在浏览器标签的title
     */
    title: 'poultry',
    /**
     * @description token在Cookie中存储的天数，默认1天
     */
    cookieExpires: 10,
    /**
     * @description 是否使用国际化，默认为false
     *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
     *              用来在菜单中显示文字
     */
    useI18n: false,
    /**
     * @description api请求基础路径
     */
    // baseUrl: process.env.NODE_ENV==='development' ? 'http://39.103.82.172:9898/' : 'http://39.103.82.172:9898/',
    // baseUrl: process.env.NODE_ENV==='development' ? 'http://39.103.82.172:9666/' : 'http://39.103.82.172:9666/',  // https://api.starhelix.cn/
    baseUrl: process.env.NODE_ENV==='development' ? 'https://api-egg-poultry.starhelix.cn/' : 'https://api-egg-poultry.starhelix.cn/',
    /**
     * @description 默认打开的首页的路由name值，默认为home
     */
    homeName: 'home',
    /**
     * @description 默认打开的首页的路由name值，默认为home
     */
    dateFilter: {
      ymd: 'YYYY-MM-DD',
      ymdHms: 'YYYY-MM-DD HH:mm:ss'
    },
    testUser: {
      account: 'hajay',
      password: '123456',
      roles: 1
    },
    testAdmin: {
      account: 'hajayAdmin',
      password: '123456',
      roles: 0
    },
    menuList: [
      {
        title: '家禽3D模型',
        items: [
          {
            code: 'modelinglist',
            name: '3D点云模型列表',
            pathName: 'modelingList',
            fun: [],
            des: []
          },
        ]
      },
    ]
  }
  
