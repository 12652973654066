export default [
    // {
    //     path: '/',
    //     redirect: '/login'
    // },
    // {
    //     path: '/plotly',
    //     name: 'plotly',
    //     component: () => import('@/views/PlotlyView')
    // },
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/Login'),
        meta: {
            title: '登录页'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home'),
        meta: {
            title: '鸡蛋品种识别',
            roles: [1, 3]
        }
    },
    {
        path: '/shape',
        name: 'shape',
        component: () => import('@/views/Shape'),
        meta: {
            title: '蛋形指数',
            roles: [1, 3]
        }
    },
    {
        path: '/accountlist',
        name: 'accountlist',
        component: () => import('@/views/AccountList'),
        meta: {
            title: '账号管理',
            roles: [1]
        }
    },
    {
        path: '/password',
        name: 'password',
        component: () => import('@/views/PassWord'),
        meta: {
            title: '修改密码',
            roles: [1, 3]
        }
    },
    // {
    //     path: '/help',
    //     name: 'help',
    //     component: () => import('@/views/Help'),
    //     meta: {
    //         title: '帮助',
    //         // roles: [1]
    //     }
    // },
    // {
    //     path: '/3d',
    //     name: '3d',
    //     component: () => import('@/views/3d'),
    //     meta: {
    //         title: '3d',
    //         // roles: [1]
    //     }
    // },
    // {
    //     path: '/three',
    //     name: 'three',
    //     component: () => import('@/views/Three'),
    //     meta: {
    //         title: 'Three',
    //         // roles: [1]
    //     }
    // },
    // {
    //     path: '/poultry',
    //     name: 'poultry',
    //     component: () => import('@/views/Poultry/Main'),
    //     meta: {
    //         title: '家禽MP3声音标签系统',
    //         roles: [1]
    //     },
    //     children: [
    //         {
    //             path: 'tag',
    //             name: 'mp3List',
    //             component: () => import('@/views/Poultry/MP3List'),
    //             meta: {
    //                 title: '家禽MP3声音标签系统',
    //                 roles: [1]
    //             }
    //         },
    //     ]
    // },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/404')
    }
]